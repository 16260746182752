<template>
  <div>
    <div class="content-section introduction">
      <div class="feature-intro">
        <h1>{{ tLabel("Report Soste") }}</h1>
      </div>
    </div>

    <!-- <v-container fluid class="ma-0 pa-0">
      <v-row align="center" class="ma-0 pa-0">
        <v-col cols="2">
          <DateTimeAdapter label="Data In Da" defaultTime="00:00:00" value="" />
        </v-col>
        <v-col cols="2">
          <DateTimeAdapter label="Data In A" defaultTime="23:59:59" value="" />
        </v-col>
        <v-col cols="2">
          <v-text-field :label="tLabel('Owner')" />
        </v-col>
        <v-col cols="4">
          <template>
            <v-text-field :label="tLabel('Type Handling')" />
          </template>
        </v-col>
      </v-row>
    </v-container> -->

    <v-toolbar dense flat>
      <v-spacer />
      <v-btn icon title="Applica filtri" @click="refresh">
        <v-icon color="primary" medium>mdi-find-replace</v-icon>
      </v-btn>
      <v-icon large />
      <!-- <v-btn icon title="Cancella filtri" @click="cleanFilters">
        <v-icon color="primary" medium>mdi-broom</v-icon>
      </v-btn> -->
      <v-btn icon title="Aggiungi" @click="goToCreateReport">
        <v-icon color="info" medium>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-data-table
      :headers="columnsHeader"
      :items="items"
      :options.sync="tableOptions"
      :server-items-length="totalRecords"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100, 250, 1000],
      }"
    >
      <template v-slot:item="row">
        <tr>
          <td>
            <div v-if="row.item.state == 'COMPLETED'">
              <v-btn fab dark small color="pink" @click="downloadReport(row.item.id)">
                <v-icon dark>mdi-download</v-icon>
              </v-btn>
            </div>
          </td>
          <td>{{ row.item.startTime | formatShortDateFormat }}</td>
          <td>{{ row.item.endTime | formatShortDateFormat }}</td>
          <td>{{ row.item.client }}</td>
          <td>{{ row.item.state }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";
import EnumVisualization from "@/components/yard/EnumVisualization.vue";
import FormatsMixins from "../../mixins/FormatsMixins";
import ToastMixin from '../../mixins/ToastMixin';

export default {
  data() {
    return {
      items: [],
      totalRecords: 0,
      tableOptions: null,
      headers: [
        { text: "Download", value: "actions" },
        { text: `${this.tLabel("Data dal")}`, value: "startTime" },
        { text: `${this.tLabel("Data al")}`, value: "endTime" },
        { text: `${this.tLabel("Cliente")}`, value: "client" },
        { text: `${this.tLabel("Status")}`, value: "state" },
      ],
    };
  },
  async created() {
    try {
    } catch (e) {}
  },
  async mounted() {
    console.log("--------------- mounted ---------------------");
    await this.refreshData();
  },
  computed: {
    columnsHeader() {
      let c = [];
      this.headers.forEach(function(item) {
        c.push({ class: "header text-uppercase", ...item });
      });
      return c;
    },
  },
  watch: {
    tableOptions: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
  },
  methods: {
    async refreshData() {
      //console.log(this.tableOptions.page);
      //console.log(this.tableOptions.itemsPerPage);
      var handlingHeader = await this.$api.reportData.getListReportHeader("LAYOVER", this.tableOptions.page - 1, this.tableOptions.itemsPerPage);
      //console.log("handlingHeader", handlingHeader.content);
      this.items = handlingHeader.content;
      this.totalRecords = handlingHeader.totalElements;
    },

    async refresh() {
      await this.refreshData();
    },
    cleanFilters() {},

    goToCreateReport() {
      this.$router.push({ path: "/report/createLayoverHeader" });
      return;
    },

    async downloadReport(id) {
      try {
        const _callConfig = {
          responseType: "blob",
          headers: {
            "Content-Type": "application/octet-stream",
            Accept: "*/*",
            Pragma: "no-cache",
          },
        };
        var url = `${this.$apiConfiguration.BASE_PATH_REPORT}terminal-reports/export/` + id;
        const response = await this.$api.get(url, _callConfig);
        console.log("------------------- layover header response", response);
        const fileURL = window.URL.createObjectURL(new Blob([response], {}));
        const fileLink = document.createElement("a");
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute("download", "Report Soste.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch (e) {
        this.showError("Generazione Report Soste non andata a buon fine");
        console.log(e);
      }
    },
  },
  mixins: [FormatsMixins, ToastMixin],

  components: { DateTimeAdapter, EnumVisualization },
};
</script>
